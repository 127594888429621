@import url("https://fonts.googleapis.com/css2?family=Space+Mono&display=swap");
@font-face {
  font-family: 'gellix';
  src: url("/assets/fonts/Gellix-SemiBold.woff2") format('woff2'), url("/assets/fonts/Gellix-SemiBold.woff") format('woff'), url("/assets/fonts/Gellix-SemiBold.otf") format('otf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'gellix';
  src: url("/assets/fonts/Gellix-Regular.woff2") format('woff2'), url("/assets/fonts/Gellix-Regular.woff") format('woff'), url("/assets/fonts/Gellix-Regular.otf") format('otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
html {
  cursor: url("/assets/images/cursor/cursor_default.svg") 4 4, default;
}
a,
button {
  cursor: url("/assets/images/cursor/cursor_clickable.svg") 4 4, default;
}
.workContainer {
  cursor: url("/assets/images/cursor/cursor_project.svg") 46 46, default;
}
a.definition,
.word-of-the-day {
  cursor: url("/assets/images/cursor/cursor_dictionary.svg") 16 16, default;
}
a.contactBtn {
  cursor: url("/assets/images/cursor/cursor_contact.svg") 8 28, default;
}
.dark-bg {
  background: #1d1d1d;
  color: #fff;
}
.dark-bg a,
.dark-bg button {
  color: #fff;
}
.dark-bg a.primary,
.dark-bg button.primary {
  background: #fff;
  color: #1d1d1d;
}
.dark-bg a.secondary,
.dark-bg button.secondary {
  border-color: #fff;
}
.dark-bg a.tertiary::after,
.dark-bg button.tertiary::after,
.dark-bg a.simple::after,
.dark-bg button.simple::after,
.dark-bg a.tertiary::before,
.dark-bg button.tertiary::before,
.dark-bg a.simple::before,
.dark-bg button.simple::before {
  background: #fff;
}
#dev:target .grid .w0,
#components .grid .w0 {
  position: relative;
  background: #fae9e9;
  min-height: 60px;
}
#dev:target .grid .w0::before,
#components .grid .w0::before,
#dev:target .grid .w0::after,
#components .grid .w0::after {
  content: '';
  display: block;
  position: absolute;
  width: 15px;
  top: 0;
  bottom: 0;
  background: #dff1f1;
}
#dev:target .grid .w0::before,
#components .grid .w0::before {
  left: 0;
}
#dev:target .grid .w0::after,
#components .grid .w0::after {
  right: 0;
}
#dev:target .grid .w1,
#components .grid .w1 {
  position: relative;
  background: #fae9e9;
  min-height: 60px;
}
#dev:target .grid .w1::before,
#components .grid .w1::before,
#dev:target .grid .w1::after,
#components .grid .w1::after {
  content: '';
  display: block;
  position: absolute;
  width: 15px;
  top: 0;
  bottom: 0;
  background: #dff1f1;
}
#dev:target .grid .w1::before,
#components .grid .w1::before {
  left: 0;
}
#dev:target .grid .w1::after,
#components .grid .w1::after {
  right: 0;
}
#dev:target .grid .w2,
#components .grid .w2 {
  position: relative;
  background: #fae9e9;
  min-height: 60px;
}
#dev:target .grid .w2::before,
#components .grid .w2::before,
#dev:target .grid .w2::after,
#components .grid .w2::after {
  content: '';
  display: block;
  position: absolute;
  width: 15px;
  top: 0;
  bottom: 0;
  background: #dff1f1;
}
#dev:target .grid .w2::before,
#components .grid .w2::before {
  left: 0;
}
#dev:target .grid .w2::after,
#components .grid .w2::after {
  right: 0;
}
#dev:target .grid .w3,
#components .grid .w3 {
  position: relative;
  background: #fae9e9;
  min-height: 60px;
}
#dev:target .grid .w3::before,
#components .grid .w3::before,
#dev:target .grid .w3::after,
#components .grid .w3::after {
  content: '';
  display: block;
  position: absolute;
  width: 15px;
  top: 0;
  bottom: 0;
  background: #dff1f1;
}
#dev:target .grid .w3::before,
#components .grid .w3::before {
  left: 0;
}
#dev:target .grid .w3::after,
#components .grid .w3::after {
  right: 0;
}
#dev:target .grid .w4,
#components .grid .w4 {
  position: relative;
  background: #fae9e9;
  min-height: 60px;
}
#dev:target .grid .w4::before,
#components .grid .w4::before,
#dev:target .grid .w4::after,
#components .grid .w4::after {
  content: '';
  display: block;
  position: absolute;
  width: 15px;
  top: 0;
  bottom: 0;
  background: #dff1f1;
}
#dev:target .grid .w4::before,
#components .grid .w4::before {
  left: 0;
}
#dev:target .grid .w4::after,
#components .grid .w4::after {
  right: 0;
}
#dev:target .grid .w5,
#components .grid .w5 {
  position: relative;
  background: #fae9e9;
  min-height: 60px;
}
#dev:target .grid .w5::before,
#components .grid .w5::before,
#dev:target .grid .w5::after,
#components .grid .w5::after {
  content: '';
  display: block;
  position: absolute;
  width: 15px;
  top: 0;
  bottom: 0;
  background: #dff1f1;
}
#dev:target .grid .w5::before,
#components .grid .w5::before {
  left: 0;
}
#dev:target .grid .w5::after,
#components .grid .w5::after {
  right: 0;
}
#dev:target .grid .w6,
#components .grid .w6 {
  position: relative;
  background: #fae9e9;
  min-height: 60px;
}
#dev:target .grid .w6::before,
#components .grid .w6::before,
#dev:target .grid .w6::after,
#components .grid .w6::after {
  content: '';
  display: block;
  position: absolute;
  width: 15px;
  top: 0;
  bottom: 0;
  background: #dff1f1;
}
#dev:target .grid .w6::before,
#components .grid .w6::before {
  left: 0;
}
#dev:target .grid .w6::after,
#components .grid .w6::after {
  right: 0;
}
#dev:target .grid .w7,
#components .grid .w7 {
  position: relative;
  background: #fae9e9;
  min-height: 60px;
}
#dev:target .grid .w7::before,
#components .grid .w7::before,
#dev:target .grid .w7::after,
#components .grid .w7::after {
  content: '';
  display: block;
  position: absolute;
  width: 15px;
  top: 0;
  bottom: 0;
  background: #dff1f1;
}
#dev:target .grid .w7::before,
#components .grid .w7::before {
  left: 0;
}
#dev:target .grid .w7::after,
#components .grid .w7::after {
  right: 0;
}
#dev:target .grid .w8,
#components .grid .w8 {
  position: relative;
  background: #fae9e9;
  min-height: 60px;
}
#dev:target .grid .w8::before,
#components .grid .w8::before,
#dev:target .grid .w8::after,
#components .grid .w8::after {
  content: '';
  display: block;
  position: absolute;
  width: 15px;
  top: 0;
  bottom: 0;
  background: #dff1f1;
}
#dev:target .grid .w8::before,
#components .grid .w8::before {
  left: 0;
}
#dev:target .grid .w8::after,
#components .grid .w8::after {
  right: 0;
}
#dev:target .grid .w9,
#components .grid .w9 {
  position: relative;
  background: #fae9e9;
  min-height: 60px;
}
#dev:target .grid .w9::before,
#components .grid .w9::before,
#dev:target .grid .w9::after,
#components .grid .w9::after {
  content: '';
  display: block;
  position: absolute;
  width: 15px;
  top: 0;
  bottom: 0;
  background: #dff1f1;
}
#dev:target .grid .w9::before,
#components .grid .w9::before {
  left: 0;
}
#dev:target .grid .w9::after,
#components .grid .w9::after {
  right: 0;
}
#dev:target .grid .w10,
#components .grid .w10 {
  position: relative;
  background: #fae9e9;
  min-height: 60px;
}
#dev:target .grid .w10::before,
#components .grid .w10::before,
#dev:target .grid .w10::after,
#components .grid .w10::after {
  content: '';
  display: block;
  position: absolute;
  width: 15px;
  top: 0;
  bottom: 0;
  background: #dff1f1;
}
#dev:target .grid .w10::before,
#components .grid .w10::before {
  left: 0;
}
#dev:target .grid .w10::after,
#components .grid .w10::after {
  right: 0;
}
#dev:target .grid .w11,
#components .grid .w11 {
  position: relative;
  background: #fae9e9;
  min-height: 60px;
}
#dev:target .grid .w11::before,
#components .grid .w11::before,
#dev:target .grid .w11::after,
#components .grid .w11::after {
  content: '';
  display: block;
  position: absolute;
  width: 15px;
  top: 0;
  bottom: 0;
  background: #dff1f1;
}
#dev:target .grid .w11::before,
#components .grid .w11::before {
  left: 0;
}
#dev:target .grid .w11::after,
#components .grid .w11::after {
  right: 0;
}
.grid {
  margin: 0 33px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.grid.central::before,
.grid.central::after {
  content: '';
  display: block;
  width: 16.666666666666668%;
}
.grid.central.large::before,
.grid.central.large::after {
  width: 8.333333333333334%;
}
.grid.wrap {
  flex-wrap: wrap;
}
.grid .w0 {
  display: block;
  box-sizing: border-box;
  padding: 0 15px;
  width: 0%;
}
.grid .w0.noLeftMargin,
.grid .w0.noRightMargin {
  width: calc(0% + 48px);
}
.grid .w0.noLeftMargin {
  transform: translate(-48px, 0);
}
.grid .w0.noRightMargin {
  transform: translate(48px, 0);
}
.grid .w1 {
  display: block;
  box-sizing: border-box;
  padding: 0 15px;
  width: 8.333333333333334%;
}
.grid .w1.noLeftMargin,
.grid .w1.noRightMargin {
  width: calc(8.333333333333334% + 48px);
}
.grid .w1.noLeftMargin {
  transform: translate(-48px, 0);
}
.grid .w1.noRightMargin {
  transform: translate(48px, 0);
}
.grid .w2 {
  display: block;
  box-sizing: border-box;
  padding: 0 15px;
  width: 16.666666666666668%;
}
.grid .w2.noLeftMargin,
.grid .w2.noRightMargin {
  width: calc(16.666666666666668% + 48px);
}
.grid .w2.noLeftMargin {
  transform: translate(-48px, 0);
}
.grid .w2.noRightMargin {
  transform: translate(48px, 0);
}
.grid .w3 {
  display: block;
  box-sizing: border-box;
  padding: 0 15px;
  width: 25%;
}
.grid .w3.noLeftMargin,
.grid .w3.noRightMargin {
  width: calc(25% + 48px);
}
.grid .w3.noLeftMargin {
  transform: translate(-48px, 0);
}
.grid .w3.noRightMargin {
  transform: translate(48px, 0);
}
.grid .w4 {
  display: block;
  box-sizing: border-box;
  padding: 0 15px;
  width: 33.333333333333336%;
}
.grid .w4.noLeftMargin,
.grid .w4.noRightMargin {
  width: calc(33.333333333333336% + 48px);
}
.grid .w4.noLeftMargin {
  transform: translate(-48px, 0);
}
.grid .w4.noRightMargin {
  transform: translate(48px, 0);
}
.grid .w5 {
  display: block;
  box-sizing: border-box;
  padding: 0 15px;
  width: 41.666666666666664%;
}
.grid .w5.noLeftMargin,
.grid .w5.noRightMargin {
  width: calc(41.666666666666664% + 48px);
}
.grid .w5.noLeftMargin {
  transform: translate(-48px, 0);
}
.grid .w5.noRightMargin {
  transform: translate(48px, 0);
}
.grid .w6 {
  display: block;
  box-sizing: border-box;
  padding: 0 15px;
  width: 50%;
}
.grid .w6.noLeftMargin,
.grid .w6.noRightMargin {
  width: calc(50% + 48px);
}
.grid .w6.noLeftMargin {
  transform: translate(-48px, 0);
}
.grid .w6.noRightMargin {
  transform: translate(48px, 0);
}
.grid .w7 {
  display: block;
  box-sizing: border-box;
  padding: 0 15px;
  width: 58.333333333333336%;
}
.grid .w7.noLeftMargin,
.grid .w7.noRightMargin {
  width: calc(58.333333333333336% + 48px);
}
.grid .w7.noLeftMargin {
  transform: translate(-48px, 0);
}
.grid .w7.noRightMargin {
  transform: translate(48px, 0);
}
.grid .w8 {
  display: block;
  box-sizing: border-box;
  padding: 0 15px;
  width: 66.66666666666667%;
}
.grid .w8.noLeftMargin,
.grid .w8.noRightMargin {
  width: calc(66.66666666666667% + 48px);
}
.grid .w8.noLeftMargin {
  transform: translate(-48px, 0);
}
.grid .w8.noRightMargin {
  transform: translate(48px, 0);
}
.grid .w9 {
  display: block;
  box-sizing: border-box;
  padding: 0 15px;
  width: 75%;
}
.grid .w9.noLeftMargin,
.grid .w9.noRightMargin {
  width: calc(75% + 48px);
}
.grid .w9.noLeftMargin {
  transform: translate(-48px, 0);
}
.grid .w9.noRightMargin {
  transform: translate(48px, 0);
}
.grid .w10 {
  display: block;
  box-sizing: border-box;
  padding: 0 15px;
  width: 83.33333333333333%;
}
.grid .w10.noLeftMargin,
.grid .w10.noRightMargin {
  width: calc(83.33333333333333% + 48px);
}
.grid .w10.noLeftMargin {
  transform: translate(-48px, 0);
}
.grid .w10.noRightMargin {
  transform: translate(48px, 0);
}
.grid .w11 {
  display: block;
  box-sizing: border-box;
  padding: 0 15px;
  width: 91.66666666666667%;
}
.grid .w11.noLeftMargin,
.grid .w11.noRightMargin {
  width: calc(91.66666666666667% + 48px);
}
.grid .w11.noLeftMargin {
  transform: translate(-48px, 0);
}
.grid .w11.noRightMargin {
  transform: translate(48px, 0);
}
.grid .w12 {
  display: block;
  box-sizing: border-box;
  padding: 0 15px;
  width: 100%;
}
.grid .w12.noLeftMargin,
.grid .w12.noRightMargin {
  width: calc(100% + 48px);
}
.grid .w12.noLeftMargin {
  transform: translate(-48px, 0);
}
.grid .w12.noRightMargin {
  transform: translate(48px, 0);
}
.heroShapes,
.shapes,
.lottie {
  position: absolute;
  z-index: -1;
}
.heroShapes,
.shapes {
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
}
.heroShapes {
  height: 55.55555555555556vw;
  pointer-events: none;
}
html,
body {
  margin: 0;
  background: #fff;
}
section {
  padding-top: 270px;
}
section.smallTop {
  padding-top: 144px;
}
section.noTop {
  padding-top: 0;
}
section.lastSection {
  padding-bottom: 270px;
}
section.lastSection.noBottom {
  padding-bottom: 0;
}
.relative-box {
  position: relative;
}
hr {
  margin: 72px 0;
  border: none;
  border-bottom: 2px solid #e1e3e5;
}
hr.dark {
  border-bottom: 1px solid #6e6f70;
}
#cache-debug {
  display: none;
}
#dev:target #cache-debug {
  display: block;
  position: fixed;
  z-index: 2000;
  top: 100vh;
  left: 50%;
  transform: translate(-50%, -150%);
  background: #fff;
  font-size: 12px;
  padding: 6px 18px;
  border-radius: 20px;
}
div[data-oembed-type="video"] iframe {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  margin-bottom: 18px;
}
#js-contentBox {
  transition: opacity 0.5s ease-in;
  opacity: 1;
}
html.is-animating {
  pointer-events: none;
}
html.is-animating #js-contentBox {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}
html.is-animating-to-work,
html.is-animating-to-article {
  pointer-events: none;
}
html.is-animating-to-work .transi-screen,
html.is-animating-to-article .transi-screen {
  transform: scaleX(100%);
}
html.is-animating-to-work.ending-animation-to-work .transi-screen,
html.is-animating-to-article.ending-animation-to-work .transi-screen,
html.is-animating-to-work.ending-animation-to-article .transi-screen,
html.is-animating-to-article.ending-animation-to-article .transi-screen {
  transform-origin: top;
  transform: scaleY(0%);
  transition: transform 0.3s ease-in;
}
html.is-animating-to-work.ending-animation-to-work #transition-article-preview,
html.is-animating-to-article.ending-animation-to-work #transition-article-preview,
html.is-animating-to-work.ending-animation-to-article #transition-article-preview,
html.is-animating-to-article.ending-animation-to-article #transition-article-preview {
  transition: transform 0.3s ease-in;
}
#transition-work-image,
#transition-article-preview {
  position: fixed;
  z-index: 200;
  transform-origin: top left;
  will-change: transform;
  transition: transform 0.7s ease-in-out;
}
#transition-article-preview {
  color: #fff;
}
.transi-screen {
  position: fixed;
  z-index: 199;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #1d1d1d;
  transform: scaleX(0);
  transform-origin: right;
  will-change: transform;
  transition: transform 0.5s ease-in-out;
}
section.page404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 180px;
}
section.page404 a.primary {
  margin-top: 30px;
}
.contentContainer {
  height: 100vh;
  width: 100vw;
  position: fixed;
  overflow: hidden;
}
.contentContainer .contentBox {
  will-change: transform;
}
html::-webkit-scrollbar {
  display: none;
}
html {
  -ms-overflow-style: none /* IE and Edge */;
  scrollbar-width: none /* Firefox */;
}
@media (pointer: coarse) {
  .contentContainer {
    height: auto;
    position: static;
  }
  .contentContainer .contentBox {
    transform: none !important;
    will-change: none;
  }
}
@media screen and (min-width: 1600px) {
  .grid {
    margin: 0 auto;
    max-width: 1534px;
  }
  #contact .grid {
    max-width: none;
  }
}
@media screen and (min-width: 1600px) {
  .h100 {
    min-height: 100vh;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  .h100 section {
    width: 100%;
  }
  .h60 {
    min-height: 60vh;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  .h60 section {
    width: 100%;
  }
}
@media screen and (max-width: 1100px) {
  .grid.central.large-1100::before,
  .grid.central.large-1100::after {
    width: 8.333333333333334%;
  }
  .grid .w0-1100 {
    display: none;
  }
  .grid .w0-1100 {
    width: 0%;
  }
  .grid .w1-1100 {
    width: 8.333333333333334%;
  }
  .grid .w2-1100 {
    width: 16.666666666666668%;
  }
  .grid .w3-1100 {
    width: 25%;
  }
  .grid .w4-1100 {
    width: 33.333333333333336%;
  }
  .grid .w5-1100 {
    width: 41.666666666666664%;
  }
  .grid .w6-1100 {
    width: 50%;
  }
  .grid .w7-1100 {
    width: 58.333333333333336%;
  }
  .grid .w8-1100 {
    width: 66.66666666666667%;
  }
  .grid .w9-1100 {
    width: 75%;
  }
  .grid .w10-1100 {
    width: 83.33333333333333%;
  }
  .grid .w11-1100 {
    width: 91.66666666666667%;
  }
  .grid .w12-1100 {
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .grid.central.large-900::before,
  .grid.central.large-900::after {
    width: 8.333333333333334%;
  }
  .grid .w0-900 {
    display: none;
  }
  .grid .w0-900 {
    width: 0%;
  }
  .grid .w1-900 {
    width: 8.333333333333334%;
  }
  .grid .w2-900 {
    width: 16.666666666666668%;
  }
  .grid .w3-900 {
    width: 25%;
  }
  .grid .w4-900 {
    width: 33.333333333333336%;
  }
  .grid .w5-900 {
    width: 41.666666666666664%;
  }
  .grid .w6-900 {
    width: 50%;
  }
  .grid .w7-900 {
    width: 58.333333333333336%;
  }
  .grid .w8-900 {
    width: 66.66666666666667%;
  }
  .grid .w9-900 {
    width: 75%;
  }
  .grid .w10-900 {
    width: 83.33333333333333%;
  }
  .grid .w11-900 {
    width: 91.66666666666667%;
  }
  .grid .w12-900 {
    width: 100%;
  }
}
@media screen and (max-width: 700px) {
  .grid {
    flex-wrap: wrap;
  }
  .grid .w0-700 {
    display: none;
  }
  .grid .w0-700 {
    width: 0%;
  }
  .grid .w1-700 {
    width: 8.333333333333334%;
  }
  .grid .w2-700 {
    width: 16.666666666666668%;
  }
  .grid .w3-700 {
    width: 25%;
  }
  .grid .w4-700 {
    width: 33.333333333333336%;
  }
  .grid .w5-700 {
    width: 41.666666666666664%;
  }
  .grid .w6-700 {
    width: 50%;
  }
  .grid .w7-700 {
    width: 58.333333333333336%;
  }
  .grid .w8-700 {
    width: 66.66666666666667%;
  }
  .grid .w9-700 {
    width: 75%;
  }
  .grid .w10-700 {
    width: 83.33333333333333%;
  }
  .grid .w11-700 {
    width: 91.66666666666667%;
  }
  .grid .w12-700 {
    width: 100%;
  }
  #dev:target .grid .w0::before,
  #components .grid .w0::before,
  #dev:target .grid .w0::after,
  #components .grid .w0::after {
    width: 6px;
  }
  #dev:target .grid .w1::before,
  #components .grid .w1::before,
  #dev:target .grid .w1::after,
  #components .grid .w1::after {
    width: 6px;
  }
  #dev:target .grid .w2::before,
  #components .grid .w2::before,
  #dev:target .grid .w2::after,
  #components .grid .w2::after {
    width: 6px;
  }
  #dev:target .grid .w3::before,
  #components .grid .w3::before,
  #dev:target .grid .w3::after,
  #components .grid .w3::after {
    width: 6px;
  }
  #dev:target .grid .w4::before,
  #components .grid .w4::before,
  #dev:target .grid .w4::after,
  #components .grid .w4::after {
    width: 6px;
  }
  #dev:target .grid .w5::before,
  #components .grid .w5::before,
  #dev:target .grid .w5::after,
  #components .grid .w5::after {
    width: 6px;
  }
  #dev:target .grid .w6::before,
  #components .grid .w6::before,
  #dev:target .grid .w6::after,
  #components .grid .w6::after {
    width: 6px;
  }
  #dev:target .grid .w7::before,
  #components .grid .w7::before,
  #dev:target .grid .w7::after,
  #components .grid .w7::after {
    width: 6px;
  }
  #dev:target .grid .w8::before,
  #components .grid .w8::before,
  #dev:target .grid .w8::after,
  #components .grid .w8::after {
    width: 6px;
  }
  #dev:target .grid .w9::before,
  #components .grid .w9::before,
  #dev:target .grid .w9::after,
  #components .grid .w9::after {
    width: 6px;
  }
  #dev:target .grid .w10::before,
  #components .grid .w10::before,
  #dev:target .grid .w10::after,
  #components .grid .w10::after {
    width: 6px;
  }
  #dev:target .grid .w11::before,
  #components .grid .w11::before,
  #dev:target .grid .w11::after,
  #components .grid .w11::after {
    width: 6px;
  }
  .grid {
    margin: 0 12px;
  }
  .grid.central::before,
  .grid.central::after {
    content: none;
  }
  .grid .w0 {
    padding: 0 6px;
  }
  .grid .w0.noLeftMargin,
  .grid .w0.noRightMargin {
    width: calc(0% + 24px);
    transform: translate(-24px, 0);
  }
  .grid .w1 {
    padding: 0 6px;
  }
  .grid .w1.noLeftMargin,
  .grid .w1.noRightMargin {
    width: calc(8.333333333333334% + 24px);
    transform: translate(-24px, 0);
  }
  .grid .w2 {
    padding: 0 6px;
  }
  .grid .w2.noLeftMargin,
  .grid .w2.noRightMargin {
    width: calc(16.666666666666668% + 24px);
    transform: translate(-24px, 0);
  }
  .grid .w3 {
    padding: 0 6px;
  }
  .grid .w3.noLeftMargin,
  .grid .w3.noRightMargin {
    width: calc(25% + 24px);
    transform: translate(-24px, 0);
  }
  .grid .w4 {
    padding: 0 6px;
  }
  .grid .w4.noLeftMargin,
  .grid .w4.noRightMargin {
    width: calc(33.333333333333336% + 24px);
    transform: translate(-24px, 0);
  }
  .grid .w5 {
    padding: 0 6px;
  }
  .grid .w5.noLeftMargin,
  .grid .w5.noRightMargin {
    width: calc(41.666666666666664% + 24px);
    transform: translate(-24px, 0);
  }
  .grid .w6 {
    padding: 0 6px;
  }
  .grid .w6.noLeftMargin,
  .grid .w6.noRightMargin {
    width: calc(50% + 24px);
    transform: translate(-24px, 0);
  }
  .grid .w7 {
    padding: 0 6px;
  }
  .grid .w7.noLeftMargin,
  .grid .w7.noRightMargin {
    width: calc(58.333333333333336% + 24px);
    transform: translate(-24px, 0);
  }
  .grid .w8 {
    padding: 0 6px;
  }
  .grid .w8.noLeftMargin,
  .grid .w8.noRightMargin {
    width: calc(66.66666666666667% + 24px);
    transform: translate(-24px, 0);
  }
  .grid .w9 {
    padding: 0 6px;
  }
  .grid .w9.noLeftMargin,
  .grid .w9.noRightMargin {
    width: calc(75% + 24px);
    transform: translate(-24px, 0);
  }
  .grid .w10 {
    padding: 0 6px;
  }
  .grid .w10.noLeftMargin,
  .grid .w10.noRightMargin {
    width: calc(83.33333333333333% + 24px);
    transform: translate(-24px, 0);
  }
  .grid .w11 {
    padding: 0 6px;
  }
  .grid .w11.noLeftMargin,
  .grid .w11.noRightMargin {
    width: calc(91.66666666666667% + 24px);
    transform: translate(-24px, 0);
  }
  .grid .w12 {
    padding: 0 6px;
  }
  .grid .w12.noLeftMargin,
  .grid .w12.noRightMargin {
    width: calc(100% + 24px);
    transform: translate(-24px, 0);
  }
}
@media screen and (max-width: 700px) {
  section {
    padding-top: 180px;
  }
  section.hero {
    padding-top: 120px;
  }
  section.smallTop {
    padding-top: 90px;
  }
  section.lastSection {
    padding-bottom: 180px;
  }
  hr {
    margin: 48px 0;
  }
}
a,
button {
  -webkit-appearance: none;
  border: none;
  display: inline-block;
  color: #1d1d1d;
  text-decoration: none;
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  font-feature-settings: unset;
}
a.primary,
button.primary,
a.secondary,
button.secondary {
  padding: 18px 36px 16px;
  border-radius: 100px;
  will-change: opacity;
  transition: opacity 0.3s ease-out;
}
a.primary:hover,
button.primary:hover,
a.secondary:hover,
button.secondary:hover {
  opacity: 0.63;
}
a.primary,
button.primary {
  color: #fff;
  background: #1d1d1d;
}
a.secondary,
button.secondary {
  box-sizing: border-box;
  border: #1d1d1d 2px solid;
}
a.tertiary,
button.tertiary,
a.simple,
button.simple {
  position: relative;
  overflow: hidden;
  padding: 6px 0;
}
a.tertiary::after,
button.tertiary::after,
a.simple::after,
button.simple::after,
a.tertiary::before,
button.tertiary::before,
a.simple::before,
button.simple::before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 1px;
  background: #1d1d1d;
  will-change: transform;
}
a.tertiary::before,
button.tertiary::before,
a.simple::before,
button.simple::before {
  opacity: 0.3;
  transition: transform 0.3s 0.1s ease-out;
}
a.tertiary::after,
button.tertiary::after,
a.simple::after,
button.simple::after {
  height: 2px;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}
a.tertiary:hover::before,
button.tertiary:hover::before,
a.simple:hover::before,
button.simple:hover::before,
a.tertiary.current::before,
button.tertiary.current::before,
a.simple.current::before,
button.simple.current::before {
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}
a.tertiary:hover::after,
button.tertiary:hover::after,
a.simple:hover::after,
button.simple:hover::after,
a.tertiary.current::after,
button.tertiary.current::after,
a.simple.current::after,
button.simple.current::after {
  transform: translateX(0%);
  transition: transform 0.3s 0.1s ease-out;
}
a.tertiary:active,
button.tertiary:active,
a.simple:active,
button.simple:active {
  transition: opacity 0.1s;
  opacity: 0.6;
}
a.simple,
button.simple {
  text-transform: lowercase;
  font-size: 20px;
  letter-spacing: 0.4px;
}
a.simple.small,
button.simple.small {
  font-size: 18px;
  letter-spacing: 0.36px;
}
a.simple.disabled,
button.simple.disabled {
  pointer-events: none;
}
a.simple.disabled::after,
button.simple.disabled::after,
a.simple.disabled::before,
button.simple.disabled::before {
  opacity: 0;
}
a.definition.auto {
  text-decoration: underline;
  text-transform: none;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  padding: 0 2px;
  margin: 0 -2px;
  font-feature-settings: inherit;
}
.data .number {
  display: block;
  padding-bottom: 12px;
  margin-bottom: 18px;
  border-bottom: 1px solid #1d1d1d;
}
.definition .definition-name {
  margin: 18px 0 36px;
}
.definition .etymology {
  color: #e2f4db;
}
.definition .definition-title {
  margin: 54px 0 24px;
}
.definition .quotation span.body {
  border-top-color: #fff;
}
.definition .workContainer {
  margin: 30px 0 60px;
}
.definition .workContainer .preview {
  width: 100%;
}
.definition .workContainer .cta {
  font-size: 12px;
  color: #6e6f70;
}
.definition .workContainer .h3.vignette {
  margin: 9px 0;
}
.definition .workContainer.reverse .previewBox {
  order: 3;
  padding-right: 0;
}
.definition .workContainer.regular .previewBox {
  padding-left: 0;
}
.definition .words-list {
  margin-top: 30px;
}
.dictionary-footer {
  padding-bottom: 0;
}
.dictionary-footer .expertises-block {
  align-items: flex-start;
}
.dictionary-footer .h3 {
  margin: 54px 0 30px;
}
.dictionary-footer .list {
  list-style: none;
  margin: 0 0 54px;
  padding: 0;
}
.dictionary-footer .dictionary-cta .body {
  color: #e2f4db;
  margin-bottom: 24px;
}
.dictionary-footer .word-of-the-day {
  display: block;
  margin-top: 180px;
  background: #fff;
  color: #1d1d1d;
  padding: 120px;
  background: url("/assets/images/gradients/yellow_green.jpg") no-repeat center;
  background-size: cover;
}
.dictionary-footer .word-of-the-day .h3 {
  margin: 0 0 48px;
}
.dictionary-footer .word-of-the-day .body {
  margin: 18px 0 24px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
}
.dictionary-footer .word-of-the-day span.simple {
  display: inline-block;
  position: relative;
  overflow: hidden;
  padding-bottom: 6px;
  color: #1d1d1d;
  text-transform: uppercase;
  font-size: 16px;
}
.dictionary-footer .word-of-the-day span.simple::after,
.dictionary-footer .word-of-the-day span.simple::before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 1px;
  background: #1d1d1d;
  will-change: transform;
}
.dictionary-footer .word-of-the-day span.simple::before {
  opacity: 0.3;
  transition: transform 0.3s 0.1s ease-out;
}
.dictionary-footer .word-of-the-day span.simple::after {
  height: 2px;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}
.dictionary-footer .word-of-the-day span.simple:hover::before,
.dictionary-footer .word-of-the-day span.simple.current::before {
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}
.dictionary-footer .word-of-the-day span.simple:hover::after,
.dictionary-footer .word-of-the-day span.simple.current::after {
  transform: translateX(0%);
  transition: transform 0.3s 0.1s ease-out;
}
.dictionary-footer .word-of-the-day span.simple::before,
.dictionary-footer .word-of-the-day span.simple::after {
  background: #1d1d1d;
}
.dictionary-footer hr {
  border-top: #6e6f70 1px solid;
  border-bottom: transparent 1px solid;
  padding: 0;
  margin: 144px 0 0;
}
body.dicoOpen {
  overflow: hidden;
}
body.dicoOpen #dictionary-modal {
  transform: translateX(0%);
}
body.dicoOpen #dictionary-modal-background {
  opacity: 1;
  transform: scaleX(100%);
}
body.dicoLoaded #dictionary-modal #dictionary-modal-content {
  opacity: 1;
}
#dictionary-modal-background {
  position: fixed;
  z-index: 149;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(29,29,29,0.5);
  transform-origin: right;
  opacity: 0;
  transform: scaleX(0%);
  will-change: opacity, transform;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}
#dictionary-modal {
  position: fixed;
  z-index: 150;
  top: 0;
  right: 0;
  left: 33.33%;
  bottom: 0;
  background: #1d1d1d;
  overflow: auto;
  transform: translateX(100vw);
  will-change: transform;
  transition: transform 0.5s ease-in-out;
}
#dictionary-modal .menuButton.simple {
  color: #fff;
  position: absolute;
  top: 48px;
  right: 30px;
}
#dictionary-modal .menuButton.simple .dotBox .dot {
  background: #fff;
}
#dictionary-modal .arrow-scroll {
  position: absolute;
  bottom: 48px;
  right: 30px;
}
#dictionary-modal #dictionary-modal-content {
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.3s ease-in-out;
  padding: 120px;
}
#dictionary-modal #dictionary-modal-content strong,
#dictionary-modal #dictionary-modal-content em {
  background: rgba(255,255,255,0.05);
}
footer {
  position: relative;
}
footer .work-together {
  text-align: center;
  padding: 180px 60px;
}
footer .work-together a {
  display: flex;
  align-items: center;
  justify-content: center;
}
footer .work-together a::after {
  content: '';
  display: inline-block;
  margin-left: 12px;
  width: 30px;
  height: 30px;
  background: url("/assets/images/icons/arrow-large.svg") no-repeat center;
  background-size: 100%;
  will-change: transform;
  transition: transform 0.3s ease-out;
}
footer .work-together a:hover::after {
  transform: translate(10%, -10%);
}
footer .links-view .links {
  padding: 36px 48px 48px;
  display: flex;
  justify-content: space-between;
}
footer .links-view .links .studio .logo {
  display: block;
  width: 144px;
  margin-bottom: 12px;
}
footer .links-view .links .studio span.tagline {
  font-weight: bold;
  letter-spacing: 0.4px;
  font-feature-settings: "ss02", "ss07", "ss09";
}
footer .links-view .links .find-us {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
footer .links-view .links .find-us .address {
  margin-bottom: 12px;
  display: flex;
  align-items: baseline;
}
footer .links-view .links .find-us .social {
  display: flex;
}
footer .links-view .links .find-us .social a {
  margin-left: 18px;
}
footer .newsletterBlock {
  pointer-events: none;
  transform: translateY(36px);
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  justify-content: space-between;
  padding: 60px;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background: #edf0f2;
}
footer .newsletterBlock.visible {
  pointer-events: auto;
  transform: translateY(0);
  opacity: 1;
}
footer .newsletterBlock .input-box {
  width: auto;
  flex: 1;
  margin: 0 36px 30px 48px;
}
footer .newsletterBlock input {
  background: #edf0f2;
}
footer .newsletterBlock button.close-form {
  -webkit-appearance: none;
  border: none;
  width: 24px;
  height: 24px;
  margin-left: 24px;
  background: url("/assets/images/icons/cross.svg") no-repeat center;
  background-size: 100%;
}
.address span {
  display: block;
}
.address span:last-of-type {
  display: inline-block;
}
.address::after {
  content: '';
  display: inline-block;
  margin-left: 6px;
  width: 12px;
  height: 12px;
  background: url("/assets/images/icons/arrow-small.svg") no-repeat center;
  background-size: 100%;
  transform: translateY(1px);
  will-change: transform;
  transition: transform 0.3s ease-out;
}
.address:hover::after {
  transform: translate(10%, -10%);
}
body.backgroundIsDark header .logo {
  filter: invert();
}
body.backgroundIsDark header nav a {
  color: #fff;
}
body.backgroundIsDark header nav a.simple::before,
body.backgroundIsDark header nav a.simple::after {
  background: #fff;
}
body.backgroundIsDark header .menuButton.simple {
  color: #fff;
}
body.backgroundIsDark header .menuButton.simple .dotBox .dot {
  background: #fff;
}
body.forceWhiteContact .always-on-contact-overlay.body {
  mix-blend-mode: normal;
}
header {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;
  padding: 48px 30px 0 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header .logo {
  display: flex;
  align-items: center;
  height: 30px;
  transition: filter 0.3s;
}
header .logo img {
  height: 100%;
}
header .logo .logo-text {
  will-change: opacity, transform;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}
header nav {
  display: flex;
  align-items: center;
  will-change: opacity, transform;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}
header nav a,
header nav a.simple {
  transition: color 0.3s;
  color: #1d1d1d;
  font-size: 20px;
  letter-spacing: 0.4px;
  display: inline-block;
  margin-left: 30px;
  text-transform: lowercase;
}
header nav a.h3,
header nav a.simple.h3 {
  display: none;
  align-items: center;
  justify-content: center;
}
header nav a.h3::after,
header nav a.simple.h3::after {
  content: '';
  margin-top: 3px;
  margin-left: 12px;
  filter: invert();
  width: 16px;
  height: 16px;
  background: url("/assets/images/icons/arrow-small.svg") no-repeat center;
  background-size: 100%;
}
header nav .shapes {
  display: none;
}
header.hidden .logo-text,
header.hidden nav {
  opacity: 0;
  transform: translateX(-6px);
}
.menuButton.simple {
  display: none;
  position: relative;
  z-index: 200;
  color: #1d1d1d;
  align-items: center;
  padding: 0;
  transition: color 0.3s;
}
.menuButton.simple.active {
  display: flex;
}
.menuButton.simple.active .close {
  opacity: 1;
}
.menuButton.simple.active .dotBox .dot:nth-of-type(1) {
  transform: translate(-7px, 2px);
}
.menuButton.simple.active .dotBox .dot:nth-of-type(2) {
  transform: translate(-7px, -2px);
}
.menuButton.simple.active .dotBox .dot:nth-of-type(4) {
  transform: translate(7px, -2px);
}
.menuButton.simple.active .dotBox .dot:nth-of-type(5) {
  transform: translate(7px, 2px);
}
.menuButton.simple::before,
.menuButton.simple::after {
  content: none;
}
.menuButton.simple .close {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.menuButton.simple .dotBox {
  position: relative;
  height: 24px;
  width: 24px;
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
}
.menuButton.simple .dotBox .dot {
  position: absolute;
  height: 6px;
  width: 6px;
  border-radius: 3px;
  background: #1d1d1d;
  transition: transform 0.3s ease-in-out, color 0.3s;
}
.menuButton.simple .dotBox .dot:nth-of-type(4n+1) {
  top: 0px;
}
.menuButton.simple .dotBox .dot:nth-of-type(3) {
  top: 9px;
}
.menuButton.simple .dotBox .dot:nth-of-type(2n+2) {
  bottom: 0px;
}
.always-on-contact-overlay.body {
  position: fixed;
  color: #fff;
  font-feature-settings: "ss02";
  letter-spacing: 0.4px;
  font-weight: bold;
  mix-blend-mode: difference;
  z-index: 90;
  bottom: 60px;
  left: 48px;
  transform-origin: top left;
  margin-bottom: 0;
  transform: translateY(100%) rotate(-90deg);
}
.always-on-contact-overlay.body.cameleon {
  mix-blend-mode: normal;
  color: #1d1d1d;
}
.always-on-contact-overlay.body.cameleon.hidden {
  opacity: 0;
}
.input-box {
  width: 100%;
  position: relative;
  overflow: hidden;
  opacity: 0.6;
  margin-bottom: 36px;
  will-change: opacity;
  transition: opacity 0.3s ease-out;
}
.input-box.file {
  cursor: copy;
}
.input-box.file .info label {
  cursor: copy;
}
.input-box:hover,
.input-box.focus,
.input-box.error {
  opacity: 1;
}
.input-box:hover::before,
.input-box.focus::before,
.input-box.error::before {
  transform: translateX(100%);
}
.input-box:hover::after,
.input-box.focus::after,
.input-box.error::after {
  transform: translateX(0%);
}
.input-box.focus .info {
  transform: translateY(0%);
}
.input-box.focus .info label {
  transform: scale(0.8);
  opacity: 0.6;
}
.input-box.error input {
  background: #fae9e9;
}
.input-box.error .info span.error {
  opacity: 1;
  transition: opacity 0.3s ease-out;
}
.input-box .info {
  padding: 0 3px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  transform: translateY(125%);
  will-change: transform;
  transition: transform 0.3s ease-out;
}
.input-box .info label {
  flex: 1;
  cursor: text;
  will-change: transform;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  transform-origin: bottom left;
}
.input-box .info span.error {
  margin-bottom: 0;
  opacity: 0;
}
.input-box .filesDescription {
  position: absolute;
  margin-bottom: 0 !important;
  bottom: 6px;
  left: 3px;
  right: 36px;
  overflow: hidden;
  -webkit-line-clamp: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.input-box .addFileAction {
  position: absolute;
  bottom: 2px;
  right: 0;
  width: 40px;
  height: 40px;
  background: url("/assets/images/icons/plus.svg") no-repeat center;
}
.input-box input {
  width: 100%;
  border: none;
  padding: 6px 3px;
  -webkit-appearance: none;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  transition: background 0.3s ease-out;
}
.input-box input[type=file] {
  opacity: 0;
  cursor: copy;
}
.input-box::after,
.input-box::before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 1px;
  background: #1d1d1d;
  will-change: transform;
}
.input-box::before {
  opacity: 0.3;
  transition: transform 0.3s 0.1s ease-out;
}
.input-box::after {
  height: 2px;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}
.input-box:hover::before,
.input-box.current::before {
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}
.input-box:hover::after,
.input-box.current::after {
  transform: translateX(0%);
  transition: transform 0.3s 0.1s ease-out;
}
.newsletterBlock {
  display: flex;
  align-items: center;
  position: relative;
}
.newsletterBlock p.body {
  display: flex;
  align-items: center;
  margin: 0;
}
.newsletterBlock a.simple {
  margin-left: 6px;
}
.newsletterBlock .successMessage {
  position: absolute;
  justify-content: center;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #edf0f2;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}
.newsletterBlock.loading {
  pointer-events: none;
}
.newsletterBlock.success .successMessage {
  opacity: 1;
}
section.our-thinking-block {
  padding: 120px 0 180px;
}
section.our-thinking-block .h1 {
  margin-bottom: 72px;
}
section.our-thinking-block .relative-articles-box {
  position: relative;
}
section.our-thinking-block .relative-articles-box button.arrow {
  position: absolute;
  z-index: 100;
  top: 50%;
  top: calc(calc(((100vw - 66px) * 8 / 12 - 30px) * 0.5 - 18px) / 1.6 / 2 - 36px);
  width: 72px;
  height: 72px;
  background: url("/assets/images/icons/slider-arrow.svg") no-repeat center;
  background-size: 30px;
  background-color: #1d1d1d;
  border-radius: 36px;
  transition: opacity 0.2s ease-in-out;
}
section.our-thinking-block .relative-articles-box button.arrow.invisible {
  opacity: 0;
}
section.our-thinking-block .relative-articles-box button.arrow.left {
  left: 0;
}
section.our-thinking-block .relative-articles-box button.arrow.right {
  transform: rotate(180deg);
  right: 0;
}
section.our-thinking-block .articles-box {
  max-width: 100%;
  overflow: visible;
}
section.our-thinking-block .articles-box .articles-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
section.our-thinking-block .articles-box .articles-container.animated {
  transition: transform 0.3s ease-in-out;
}
section.our-thinking-block .articles-box .articles-container.locked .article {
  pointer-events: none;
}
.article {
  display: block;
  width: calc(((100vw - 66px) * 8 / 12 - 30px) * 0.5 - 18px);
  margin-right: 36px;
  transition: opacity 0.3s ease-in-out;
}
.article.low {
  opacity: 0.5;
}
.article .preview {
  width: 100%;
  aspect-ratio: 1.6;
  -o-object-fit: cover;
     object-fit: cover;
  background: #edf0f2;
}
.article .cta {
  margin: 36px 0 18px;
}
.quotation {
  position: relative;
}
.quotation blockquote.quote {
  margin: 0 0 30px;
}
.quotation span.body {
  display: inline-block;
  border-top: 1px solid #1d1d1d;
  padding-top: 12px;
}
.quotation .shapes {
  width: 28vw;
  top: -9vw;
  left: -7vw;
  right: unset;
}
.photo-slice p.body {
  margin-top: 24px;
}
.photo-slice img {
  display: block;
}
.photo-slice img.gif {
  width: 100%;
}
.photo-slice,
.quote-slice {
  margin-top: 144px;
}
.content-slice .block-img {
  margin: 48px 0;
}
.content-slice .block-img img {
  width: 100%;
}
.content-slice .h2 {
  margin-bottom: 12px;
}
.content-slice .h3 {
  margin-bottom: 18px;
}
.content-slice .body {
  margin-bottom: 48px;
}
.content-slice br {
  display: block;
  margin-bottom: 12px;
}
.content-slice a.simple {
  padding: 0;
  top: 3px;
}
html,
body {
  font-family: "gellix", arial, sans-serif;
  font-feature-settings: "ss01", "ss07";
  color: #1d1d1d;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
}
.titleStyle {
  font-feature-settings: "ss02", "ss07";
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
pre {
  margin: 30px;
  padding: 12px;
  border: 5px solid #fff;
  max-height: 50vh;
  z-index: 100;
  background: #1d1d1d;
  color: #fff;
  overflow: auto;
  font-family: "Space Mono", Andale Mono, AndaleMono, monospace;
  font-size: 12px;
}
span.row {
  display: block;
  width: 100%;
}
strong,
em {
  font-weight: 400;
  font-style: normal;
}
strong {
  background: #fae9e9;
}
em {
  background: #faf3d4;
}
.catch,
.h1,
.h2,
.h3,
.h4 {
  font-weight: 600;
}
.catch {
  font-feature-settings: "ss02", "ss07", "ss09";
  font-size: 100px;
  line-height: 1.12;
  text-align: center;
}
.h1,
.h2,
.h3 {
  text-transform: lowercase;
  font-feature-settings: "ss02", "ss07";
}
.h1.title,
.h2.title,
.h3.title {
  text-transform: none;
}
.h1 {
  font-size: 65px;
  line-height: 1.077;
}
.h2 {
  font-size: 46px;
  line-height: 1.115;
}
.h3 {
  font-size: 28px;
  line-height: 1.115;
}
.h4 {
  font-size: 20px;
  line-height: 1.115;
  text-transform: none;
}
input,
.body {
  text-transform: none;
  letter-spacing: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 13px;
}
input.m,
.body.m {
  font-size: 24px;
  line-height: 1.6;
  margin-bottom: 18px;
}
input.l,
.body.l {
  font-size: 36px;
  margin-bottom: 36px;
}
input.l p,
.body.l p {
  margin-bottom: 36px;
}
input.mention,
.body.mention {
  font-size: 16px;
  line-height: 1.3;
  margin-bottom: 8px;
}
input.list,
.body.list {
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 9px;
}
input.chapeau,
.body.chapeau {
  font-family: "Space Mono", Andale Mono, AndaleMono, monospace;
  line-height: 1.5;
}
.number {
  font-family: "Space Mono", Andale Mono, AndaleMono, monospace;
  font-size: 72px;
  letter-spacing: 1px;
}
.number.s {
  font-size: 28px;
  letter-spacing: 0;
}
.number.m {
  font-size: 48px;
}
.h2.vignette {
  text-transform: none;
  font-size: 34px;
}
.h3.vignette {
  text-transform: none;
}
.quote {
  font-family: "Space Mono", Andale Mono, AndaleMono, monospace;
  font-size: 40px;
}
.quote.small {
  font-size: 24px;
  line-height: 1.5;
}
.cta {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  font-feature-settings: none;
}
@media screen and (max-width: 1100px) {
  .definition .workContainer {
    margin-bottom: 48px;
  }
  .definition .workContainer .previewBox {
    order: 0 !important;
    margin-bottom: 24px;
  }
  .definition .workContainer .previewBox,
  .definition .workContainer .contentBox {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (max-width: 900px) {
  .definition a.secondary {
    width: 100%;
    text-align: center;
  }
}
@media screen and (max-width: 1100px) {
  .dictionary-footer .word-of-the-day {
    padding: 60px;
  }
}
@media screen and (max-width: 700px) {
  .dictionary-footer {
    padding-top: 54px;
  }
  .dictionary-footer .list.first {
    margin-bottom: 0;
  }
  .dictionary-footer .word-of-the-day {
    margin-top: 120px;
    padding: 36px 24px;
  }
  .dictionary-footer .word-of-the-day .h3 {
    margin-bottom: 12px;
  }
  .dictionary-footer hr {
    margin-top: 90px;
  }
}
@media screen and (max-width: 900px) {
  #dictionary-modal {
    left: 0;
    transform: translateY(100vh);
  }
  #dictionary-modal .arrow-scroll {
    display: none;
  }
  #dictionary-modal .definition .see-also {
    display: none;
  }
  #dictionary-modal-background {
    display: none;
    transition: none;
    will-change: unset;
  }
}
@media screen and (max-width: 700px) {
  #dictionary-modal #dictionary-modal-content {
    padding: 120px 60px 60px;
  }
}
@media screen and (max-width: 500px) {
  #dictionary-modal #dictionary-modal-content {
    padding: 120px 24px 48px;
  }
}
@media screen and (max-width: 900px) {
  footer .work-together {
    padding: 90px 36px;
  }
  footer .links-view .links {
    flex-direction: column;
    align-items: center;
  }
  footer .links-view .links .studio .logo {
    margin: 0 auto 12px;
  }
  footer .links-view .links .find-us {
    margin-top: 12px;
    align-items: center;
  }
  footer .links-view .links .find-us .social a:first-of-type {
    margin-left: 0;
  }
}
@media screen and (max-width: 700px) {
  footer .work-together {
    padding: 48px 0;
    margin: 0 18px;
    text-align: left;
    border-bottom: 1px solid #6e6f70;
  }
  footer .work-together a {
    justify-content: flex-start;
  }
  footer .links-view .links {
    padding: 48px 18px;
    align-items: flex-start;
  }
  footer .links-view .links .find-us {
    align-items: flex-start;
  }
  footer .links-view .links .find-us .address {
    margin-bottom: 24px;
  }
  footer .links-view .links .find-us .social {
    flex-direction: column;
    align-items: flex-start;
  }
  footer .links-view .links .find-us .social a {
    margin: 6px 0;
  }
  footer .links-view .links .studio {
    margin-top: 48px;
    order: 5;
  }
  footer .links-view .links .studio .logo {
    margin: 0 0 12px;
  }
  footer .newsletterBlock {
    padding: 90px 36px;
    flex-direction: column;
    align-items: center;
  }
  footer .newsletterBlock p.body.info {
    display: block;
  }
  footer .newsletterBlock p.body.info a.simple {
    display: inline;
    padding: 0;
    margin-left: 0;
  }
  footer .newsletterBlock p.body.info a.simple::after {
    content: none;
  }
  footer .newsletterBlock .input-box {
    margin: 12px 0 48px;
    width: 100%;
  }
  footer .newsletterBlock button.close-form {
    order: -1;
    margin: 0 0 36px;
  }
}
@media screen and (max-width: 700px) {
  .always-on-contact-overlay.body {
    display: none;
  }
  header {
    padding: 18px 18px 0;
  }
  header .logo {
    height: 24px;
  }
  header nav {
    position: absolute;
    z-index: 100;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background: #fff;
    top: 0;
    right: 0;
    left: 0;
    height: 100vh;
    opacity: 0;
    pointer-events: none;
  }
  header nav a,
  header nav a.simple {
    color: #1d1d1d !important;
    font-size: 38px;
    font-size: clamp(38px, 2.93vw + 28.61px, 65px);
    margin: 18px;
  }
  header nav a.h3,
  header nav a.simple.h3 {
    display: flex;
    position: absolute;
    left: 0;
    bottom: 36px;
    margin-bottom: 72px;
  }
  header nav .shapes {
    display: block;
    height: 90vw;
    width: 90vw;
    top: 50%;
    right: 0;
    left: unset;
    transform: translate(15vw, -65%) rotate(12deg);
  }
  header .menuButton.simple {
    display: flex;
  }
  header.open nav {
    opacity: 1;
    pointer-events: auto;
  }
  header.open .menuButton.simple {
    color: #1d1d1d !important;
  }
  header.open .menuButton.simple .close {
    opacity: 1;
  }
  header.open .menuButton.simple .dotBox {
    transform: rotate(90deg);
  }
  header.open .menuButton.simple .dotBox .dot {
    background: #1d1d1d !important;
  }
  header.open .menuButton.simple .dotBox .dot:nth-of-type(1) {
    transform: translate(-7px, 2px);
  }
  header.open .menuButton.simple .dotBox .dot:nth-of-type(2) {
    transform: translate(-7px, -2px);
  }
  header.open .menuButton.simple .dotBox .dot:nth-of-type(4) {
    transform: translate(7px, -2px);
  }
  header.open .menuButton.simple .dotBox .dot:nth-of-type(5) {
    transform: translate(7px, 2px);
  }
}
@media screen and (max-width: 700px) {
  .input-box {
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 500px) {
  .input-box {
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 900px) {
  section.our-thinking-block .relative-articles-box button.arrow {
    top: calc(calc(((100vw - 66px) * 10 / 12 - 30px) * 0.5 - 18px) / 1.6 / 2 - 36px);
  }
  section.our-thinking-block .articles-box .articles-container .article {
    width: calc(((100vw - 66px) * 10 / 12 - 30px) * 0.5 - 18px);
  }
}
@media screen and (max-width: 700px) {
  section.our-thinking-block .relative-articles-box button.arrow {
    display: none;
  }
  section.our-thinking-block .articles-box .articles-container .article {
    width: calc(((100vw - 24px) - 12px) * 0.9 - 18px);
  }
  section.our-thinking-block .articles-box .articles-container .article.low {
    opacity: 1;
  }
  section.our-thinking-block .articles-box .articles-container .article .cta {
    margin: 24px 0 12px;
  }
  section.our-thinking-block .thinking-title {
    margin-bottom: 0;
  }
  section.our-thinking-block .thinking-title::after {
    content: '';
    display: block;
    background: url("/assets/images/icons/arrow-scroll.svg") no-repeat center;
    transform: rotate(-90deg) translateX(-6px);
    background-size: contain;
    width: 60px;
    height: 48px;
    margin: 0 0 12px;
  }
}
@media screen and (max-width: 700px) {
  .quotation .shapes {
    left: 0;
  }
}
@media screen and (max-width: 500px) {
  .quotation .shapes {
    left: 5%;
    width: 80%;
    top: -21vw;
  }
}
@media screen and (max-width: 1240px) {
  .catch {
    font-size: 50px;
    font-size: clamp(50px, 5.43vw + 32.61px, 100px);
  }
  .h1 {
    font-size: 38px;
    font-size: clamp(38px, 2.93vw + 28.61px, 65px);
  }
  .h2 {
    font-size: 32px;
    font-size: clamp(32px, 1.52vw + 27.13px, 46px);
  }
  .h2.vignette {
    font-size: 28px;
    font-size: clamp(28px, 0.65vw + 25.91px, 34px);
  }
  .h3 {
    font-size: 22px;
    font-size: clamp(22px, 0.65vw + 19.91px, 28px);
  }
  input,
  .body {
    font-size: 18px;
    font-size: clamp(18px, 0.22vw + 17.3px, 20px);
  }
  input.m,
  .body.m {
    font-size: 20px;
    font-size: clamp(20px, 0.65vw + 17.91px, 26px);
  }
  input.l,
  .body.l {
    font-size: 24px;
    font-size: clamp(24px, 1.3vw + 19.83px, 36px);
  }
  input.mention,
  .body.mention {
    font-size: 15px;
    font-size: clamp(15px, 0.11vw + 14.65px, 16px);
  }
  input.list,
  .body.list {
    font-size: 18px;
    font-size: clamp(18px, 0.65vw + 15.91px, 24px);
  }
  .number {
    font-size: 54px;
    font-size: clamp(54px, 1.96vw + 47.74px, 72px);
  }
  .number.s {
    font-size: 24px;
    font-size: clamp(24px, 0.43vw + 22.61px, 28px);
  }
  .number.m {
    font-size: 30px;
    font-size: clamp(30px, 1.96vw + 23.74px, 48px);
  }
  .quote {
    font-size: 22px;
    font-size: clamp(22px, 1.96vw + 15.74px, 40px);
  }
  .quote.small {
    font-size: 18px;
    font-size: clamp(18px, 0.65vw + 15.91px, 24px);
  }
  .cta,
  a,
  button {
    font-size: 14px;
    font-size: clamp(14px, 0.22vw + 13.3px, 16px);
  }
  .cta.simple,
  a.simple,
  button.simple {
    font-size: 18px;
    font-size: clamp(18px, 0.22vw + 17.3px, 20px);
  }
}
#article main .hero {
  text-align: center;
  padding-top: 180px;
  padding-bottom: 180px;
}
#article main .hero .h1 {
  margin: 24px 0 36px;
}
#article main .hero .body.mention {
  margin: 6px 0 54px;
}
#article main .hero .video,
#article main .hero .hero-image {
  width: 100%;
  max-width: 100%;
  margin-bottom: 90px;
}
#article main .hero .video {
  aspect-ratio: 2/1;
}
#article main .detailed-content {
  padding-top: 144px;
  margin-bottom: 144px;
}
#article main .detailed-content .author-infos {
  margin-top: 120px;
}
#article main .detailed-content .author-infos .h4 {
  margin-bottom: 6px;
}
#components main {
  padding: 80px 0 400px;
}
#components .list {
  display: flex;
  align-items: center;
  padding: 100px 0;
}
#components .list a,
#components .list button {
  margin: 0 40px;
}
#components form {
  padding: 80px;
  max-width: 600px;
}
#contact .always-on-contact-overlay {
  display: none;
}
#contact main {
  height: 100vh;
}
#contact main .grid {
  height: 100%;
}
#contact main .grid.background {
  align-items: stretch;
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
}
#contact main .grid.background .sideBackground {
  background: #e2f4db;
  overflow: hidden;
  position: relative;
}
#contact main .grid.background .sideBackground picture {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
#contact main .grid.background .sideBackground img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#contact main .grid.background .sideBackground img.startProject,
#contact main .grid.background .sideBackground img.joinTheTeam {
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.5s ease-in-out;
}
#contact main .sidePanel {
  position: relative;
  height: 100%;
}
#contact main .sidePanel a.mail {
  margin: 36px 0 18px;
}
#contact main .sidePanel a.address {
  display: block;
}
#contact main .sidePanel a.address::after {
  filter: invert();
}
#contact main .sidePanel .message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  will-change: opacity;
  transition: opacity 0.5s ease-in-out;
}
#contact main .sidePanel .message.secondary {
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  left: 15px;
  opacity: 0;
  pointer-events: none;
}
#contact main .sidePanel .message.secondary span.h1 {
  display: block;
  margin-bottom: 48px;
}
#contact main .sidePanel .message.secondary svg {
  height: 48px;
  margin-bottom: 12px;
}
#contact main.step1 .blockContainer .block.starter {
  opacity: 0;
  transform: translateY(-90px);
}
#contact main.project .grid.background .sideBackground img.startProject {
  opacity: 1;
}
#contact main.project.step1 .blockContainer .block.step.project-1 {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}
#contact main.project.step2 .blockContainer .block.step.project-1 {
  opacity: 0;
  transform: translateY(-90px);
  pointer-events: none;
}
#contact main.project.step2 .blockContainer .block.step.project-2 {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}
#contact main.team .grid.background .sideBackground img.joinTheTeam {
  opacity: 1;
}
#contact main.team.step1 .blockContainer .block.step.team-1 {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}
#contact main.team.step2 .blockContainer .block.step.team-1 {
  opacity: 0;
  transform: translateY(-90px);
  pointer-events: none;
}
#contact main.team.step2 .blockContainer .block.step.team-2 {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}
#contact main.project.success .grid.background .sideBackground img.joinTheTeam,
#contact main.team.success .grid.background .sideBackground img.joinTheTeam,
#contact main.project.success .grid.background .sideBackground img.startProject,
#contact main.team.success .grid.background .sideBackground img.startProject {
  opacity: 0;
}
#contact main.project.success .blockContainer .block.step.team-1,
#contact main.team.success .blockContainer .block.step.team-1,
#contact main.project.success .blockContainer .block.step.team-2,
#contact main.team.success .blockContainer .block.step.team-2,
#contact main.project.success .blockContainer .block.step.project-1,
#contact main.team.success .blockContainer .block.step.project-1,
#contact main.project.success .blockContainer .block.step.project-2,
#contact main.team.success .blockContainer .block.step.project-2 {
  opacity: 0;
  transform: translateY(-90px);
  pointer-events: none;
}
#contact main.project.success .blockContainer .block.step.success,
#contact main.team.success .blockContainer .block.step.success {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}
#contact main.project.success .sidePanel .message.initial,
#contact main.team.success .sidePanel .message.initial {
  opacity: 0;
  pointer-events: none;
}
#contact main.project.success .sidePanel .message.secondary.success,
#contact main.team.success .sidePanel .message.secondary.success {
  opacity: 1;
}
#contact main.project.newsletterSuccess .sidePanel .message.secondary.success,
#contact main.team.newsletterSuccess .sidePanel .message.secondary.success {
  opacity: 0;
}
#contact main.project.newsletterSuccess .sidePanel .message.secondary.newsletterSuccess,
#contact main.team.newsletterSuccess .sidePanel .message.secondary.newsletterSuccess {
  opacity: 1;
}
#contact main.project.newsletterSuccess .blockContainer .block.step.success,
#contact main.team.newsletterSuccess .blockContainer .block.step.success {
  opacity: 0;
  transform: translateY(-90px);
  pointer-events: none;
}
#contact main.project.newsletterSuccess .blockContainer .block.step.newsletterSuccess,
#contact main.team.newsletterSuccess .blockContainer .block.step.newsletterSuccess {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}
#contact main .blockContainer {
  position: relative;
}
#contact main .blockContainer .block {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  will-change: opacity, transform;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}
#contact main .blockContainer .block.success h3 {
  margin-bottom: 18px;
}
#contact main .blockContainer .block.success .newsletter-box {
  display: flex;
  justify-content: space-between;
}
#contact main .blockContainer .block.success .newsletter-box .event-column {
  width: 50%;
  width: calc(50% - 24px);
}
#contact main .blockContainer .block.success .newsletter-box .event-column .h3 {
  margin: 18px 0 12px;
}
#contact main .blockContainer .block.success .newsletterBlock .input-box {
  width: auto;
  flex: 1;
  margin-right: 12px;
}
#contact main .blockContainer .block.success .newsletterBlock .successMessage {
  margin-bottom: 0;
}
#contact main .blockContainer .block.step {
  position: absolute;
  top: 0;
  right: 15px;
  left: 15px;
  background: #fff;
  pointer-events: none;
  transform: translateY(90px);
  opacity: 0;
}
#contact main .blockContainer .block.step .backButton {
  height: 48px;
  width: 48px;
  background: url("/assets/images/icons/arrow-back.svg") no-repeat center;
  background-size: 100%;
}
#contact main .blockContainer .block.step h2 {
  margin-bottom: 18px;
}
#contact main .blockContainer .block.step p {
  margin-bottom: 36px;
}
#contact main .blockContainer .block.step .submitZone {
  margin-top: 12px;
}
#contact main .blockContainer .block.step .submitZone button.primary {
  width: 200px;
}
#contact main .blockContainer .block.step.newsletterSuccess .workContainer {
  display: flex;
  align-items: center;
  margin-top: 48px;
}
#contact main .blockContainer .block.step.newsletterSuccess .workContainer .previewBox {
  width: 50%;
  margin-right: 24px;
}
#contact main .blockContainer .block.step.newsletterSuccess .workContainer .previewBox .preview {
  width: 100%;
}
#contact main .blockContainer .block.step.newsletterSuccess .workContainer .contentBox {
  width: 50%;
}
#contact main .blockContainer .block.step.newsletterSuccess .workContainer .contentBox .cta {
  margin-bottom: 6px;
  color: #6e6f70;
}
#contact main .blockContainer .block.step.newsletterSuccess .workContainer .contentBox .h3 {
  margin-bottom: 6px;
}
#contact main .blockContainer .block .contactType p {
  max-width: 80%;
  margin-bottom: 24px;
}
#contact main .blockContainer .block .contactType:first-of-type {
  margin-bottom: 144px;
}
#contact footer {
  display: none;
}
#definition main .head {
  position: relative;
  padding-top: 180px;
  padding-bottom: 60px;
}
#definition main .head a.go-dictionary {
  display: block;
}
#definition main .head .h2 {
  margin-bottom: 18px;
}
#definition main .head::after {
  content: '';
  display: block;
  position: absolute;
  z-index: -2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url("/assets/images/gradients/yellow_green.jpg") no-repeat center;
  background-size: cover;
}
#definition main .head .heroShapes {
  min-height: 66vh;
}
#definition main .definition-container {
  padding-top: 60px;
  padding-bottom: 90px;
}
#definition main .definition-container .definition .modal-title {
  display: none;
}
#definition main .definition-container .definition .etymology {
  background: #e2f4db;
  color: #1d1d1d;
  padding: 36px;
}
#definition main .definition-container .definition .definition-name {
  margin-bottom: 60px;
}
#definition main .definition-container .definition .definition-title {
  margin-top: 72px;
}
#design main #lottie-design-hexagon {
  width: 22vw;
  left: 22vw;
  top: 10vh;
}
#design main #lottie-design-pentagon {
  width: 12.5vw;
  right: 14.5vw;
  top: 50vh;
}
#design main #lottie-design-bubbledDiamond {
  width: 20vw;
  left: 15vw;
  bottom: -20vw;
}
#design main .filtersList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}
#design main .filtersList a.tag {
  padding: 9px 12px;
  margin: 12px 6px;
}
#design main .filtersList a.tag::before {
  content: '#';
}
#design main .filtersList a.tag.lab {
  background: transparent !important;
  margin: 0 6px 0 18px;
  text-align: center;
  padding: 20px 0;
  aspect-ratio: 1.4;
  position: relative;
  border: none;
}
@supports not (aspect-ratio: auto) {
  #design main .filtersList a.tag.lab {
    width: 56px;
  }
}
#design main .filtersList a.tag.lab svg,
#design main .filtersList a.tag.lab::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
}
#design main .filtersList a.tag.lab::before {
  width: 1px;
  background: #6e6f70;
  left: -12px;
}
#design main .filtersList a.tag.lab svg {
  z-index: -1;
  left: 0;
  right: 0;
  background: url("/assets/images/shapes/lab_diamond.svg") no-repeat center;
  background-size: contain;
}
#design main .works {
  margin-bottom: 20vw;
  position: relative;
  transition: opacity 0.4s cubic-bezier(0.79, 0.14, 0.15, 0.86);
}
#design main .works .workContainer {
  margin-bottom: 144px;
}
#design main .works .workContainer::after {
  order: 3;
}
#design main .works .workContainer .previewBox {
  position: relative;
  overflow: hidden;
  order: 2;
}
#design main .works .workContainer .previewBox .background,
#design main .works .workContainer .previewBox .preview {
  display: block;
  aspect-ratio: 744/465;
}
#design main .works .workContainer .previewBox .background {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 15px;
  left: 15px;
}
#design main .works .workContainer .previewBox .preview {
  width: 100%;
  transform: translateY(100%) scaleY(1.8);
  transform-origin: top;
  will-change: transform;
  transition: transform 1s cubic-bezier(0.79, 0.14, 0.15, 0.86);
}
#design main .works .workContainer .cta {
  color: #6e6f70;
}
#design main .works .workContainer .h2,
#design main .works .workContainer .h3 {
  text-transform: none;
}
#design main .works .workContainer .h2 {
  margin: 18px 0;
}
#design main .works .workContainer.lab::after,
#design main .works .workContainer:nth-of-type(2n)::after {
  order: 1;
}
#design main .works .workContainer.lab .previewBox,
#design main .works .workContainer:nth-of-type(2n) .previewBox {
  order: 0;
}
#design main .works .workContainer.lab {
  flex-direction: column;
}
#design main .works .workContainer.lab .previewBox,
#design main .works .workContainer.lab .contentBox {
  width: 83.33333333333334%;
}
#design main .works .workContainer.lab .contentBox .content {
  padding: 48px 48px 36px;
}
#design main .works .workContainer.lab .contentBox .content .h2 {
  max-width: 920px;
}
#design main .works .workContainer.visible .previewBox .preview {
  transform: translateY(0px);
}
#design main.filtered.hiddenAll .works {
  opacity: 0;
}
#design main.filtered.noneDisplay .workContainer {
  display: none;
}
#design main.filtered.work .filtersList a.tag.work {
  background: #1d1d1d;
  color: #fff;
}
#design main.filtered.work .workContainer.work {
  display: flex;
}
#design main.filtered.home .filtersList a.tag.home {
  background: #1d1d1d;
  color: #fff;
}
#design main.filtered.home .workContainer.home {
  display: flex;
}
#design main.filtered.energy .filtersList a.tag.energy {
  background: #1d1d1d;
  color: #fff;
}
#design main.filtered.energy .workContainer.energy {
  display: flex;
}
#design main.filtered.industry .filtersList a.tag.industry {
  background: #1d1d1d;
  color: #fff;
}
#design main.filtered.industry .workContainer.industry {
  display: flex;
}
#design main.filtered.privacy .filtersList a.tag.privacy {
  background: #1d1d1d;
  color: #fff;
}
#design main.filtered.privacy .workContainer.privacy {
  display: flex;
}
#design main.filtered.city .filtersList a.tag.city {
  background: #1d1d1d;
  color: #fff;
}
#design main.filtered.city .workContainer.city {
  display: flex;
}
#design main.filtered.education .filtersList a.tag.education {
  background: #1d1d1d;
  color: #fff;
}
#design main.filtered.education .workContainer.education {
  display: flex;
}
#design main.filtered.healthcare .filtersList a.tag.healthcare {
  background: #1d1d1d;
  color: #fff;
}
#design main.filtered.healthcare .workContainer.healthcare {
  display: flex;
}
#design main.filtered.lab .filtersList a.tag.lab {
  color: #fff;
}
#design main.filtered.lab .filtersList a.tag.lab svg {
  fill: #1d1d1d;
}
#design main.filtered.lab .workContainer.lab {
  display: flex;
}
#dictionary main {
  position: relative;
}
#dictionary main::after {
  content: '';
  display: block;
  position: absolute;
  z-index: -2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url("/assets/images/gradients/yellow_green.jpg") no-repeat center;
  background-size: cover;
}
#dictionary main .heroShapes {
  min-height: 66vh;
}
#dictionary main .h1 {
  margin-bottom: 18px;
}
.words-list {
  margin: 90px 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.words-list li {
  width: 33.33%;
  margin-bottom: 12px;
  padding-right: 12px;
  box-sizing: border-box;
  word-wrap: anywhere;
}
#home main section.hero {
  padding-top: 0;
  position: relative;
  height: 100vh;
  width: 100vw;
}
#home main section.hero h1 {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 24px;
  bottom: 0;
  left: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#home main section.hero video {
  pointer-events: none;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
}
#home main section.hero video.loaded {
  opacity: 1;
}
#home main section.hero .arrow-scroll {
  position: absolute;
  z-index: 100;
  bottom: 30px;
  right: 30px;
  margin-bottom: 36px;
}
#home main section.introduction .lottie {
  width: 24vw;
  right: 11vw;
  top: -12vw;
}
#home main section.key-datas {
  position: relative;
  padding-bottom: 144px;
}
#home main section.key-datas .lottie {
  width: 15vw;
  right: 32vw;
  top: 100%;
}
#home main section.activity {
  position: relative;
}
#home main section.activity.last {
  margin-bottom: 144px;
}
#home main section.activity .infoOverlay {
  position: absolute;
  bottom: 72px;
  right: 0;
  left: 0;
}
#home main section.activity .infoOverlay .info {
  position: relative;
}
#home main section.activity .infoOverlay .info .content {
  position: relative;
  z-index: 2;
}
#home main section.activity .infoOverlay .info .content p {
  margin: 24px 0 30px;
}
#home main section.activity .infoOverlay .info svg {
  display: block;
  position: absolute;
  z-index: 1;
}
#home main section.activity .infoOverlay .info.consultancy-shape svg {
  top: -45%;
  left: -45%;
  width: 190%;
  height: 190%;
}
#home main section.activity .infoOverlay .info.lab-shape svg {
  transform: rotate(180deg);
  top: -50%;
  left: -40%;
  width: 180%;
  height: 180%;
}
#home main section.trust {
  position: relative;
}
#home main section.trust .lottie {
  width: 21vw;
  right: 8.5vw;
  bottom: 0;
}
#home main section.trust .title {
  margin-bottom: 54px;
}
#home main section.trust .listing {
  align-items: flex-start;
}
#home main section.trust .listing ul {
  list-style: none;
  margin: 24px 0 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
#home main section.trust .listing ul li {
  width: 50%;
}
#home main section.vision_with_echos .h2 {
  margin-bottom: 36px;
}
#home main section.vision_with_echos .echos-box {
  max-width: 100%;
  overflow: visible;
}
#home main section.vision_with_echos .echos-box .echos-container {
  padding-top: 54px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}
#home main section.vision_with_echos .echos-box .echos-container.locked .echo {
  pointer-events: none;
}
#home main section.vision_with_echos .echos-box .echos-container .echo {
  height: 64px;
}
#studio main .heroShapes {
  height: 62.5vw;
  min-height: 60vh;
}
#studio main #lottie-studio-curved-diamond {
  width: 12vw;
  top: 10vh;
  left: 26vw;
}
#studio main #lottie-studio-m {
  width: 31vw;
  top: 30vh;
  right: 14vw;
}
#studio main section.hero {
  text-align: center;
  min-height: 60vh;
  min-height: calc(100vh - 540px);
}
#studio main section.hero h1 {
  margin-bottom: 60px;
}
#studio main section.journey {
  padding-top: 0;
  margin-top: 270px;
  position: relative;
  height: 300vh;
}
#studio main section.journey .background {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  background: url("/assets/images/gradients/pink_yellow.jpg") no-repeat center;
  background-size: cover;
}
#studio main section.journey .journey-content {
  padding-left: 20vw;
  padding-left: calc(16.66vw + 48px);
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
#studio main section.journey .journey-content .block {
  width: 41.66vw;
  max-width: 480px;
  margin-right: 36px;
}
#studio main section.journey .journey-content .block h2 {
  margin-bottom: 24px;
}
#studio main section.journey .journey-content .block .arrow-scroll {
  filter: brightness(0);
  transform: rotate(-90deg);
}
#studio main section.journey .journey-content .displays {
  display: flex;
}
#studio main section.journey .journey-content .displays .display {
  display: block;
  margin-left: 36px;
  max-height: 470px;
}
#studio main section.journey .journey-content .displays .display:nth-of-type(1) {
  transform: translateY(5%);
}
#studio main section.journey .journey-content .displays .display:nth-of-type(2) {
  transform: translateY(-24%);
}
#studio main section.journey .journey-content .displays .display:nth-of-type(3) {
  transform: translateY(-7.5%);
}
#studio main section.journey .journey-content .displays .display:nth-of-type(4) {
  transform: translateY(5%);
}
#studio main section.journey .journey-content .displays .display:nth-of-type(5) {
  transform: translateY(-22%);
}
#studio main section.journey .journey-content .displays .display:nth-of-type(6) {
  transform: translateY(12%);
}
#studio main section.journey .journey-content .displays .display:nth-of-type(7) {
  transform: translateY(-8%);
}
#studio main section.events {
  padding-top: 0;
}
#studio main section.events .head {
  margin-bottom: 72px;
}
#studio main section.events .head h2 {
  margin-bottom: 24px;
}
#studio main section.events .grid {
  align-items: flex-start;
}
#studio main section.events .event-column h3 {
  margin: 48px 0 24px;
}
#studio main section.events .event-column p {
  padding-right: 36px;
}
#studio main section.events .event-column svg {
  max-width: 480px;
}
#studio main section.events .calendar {
  margin-top: 90px;
}
#studio main section.events .calendar .event {
  padding: 24px 0;
  border-bottom: 2px solid #e1e3e5;
  display: flex;
  align-items: center;
}
#studio main section.events .calendar .event:last-of-type {
  border-bottom: none;
}
#studio main section.events .calendar .event.Drink .dateBox {
  background: #faf3d4;
}
#studio main section.events .calendar .event.past {
  opacity: 0.66;
}
#studio main section.events .calendar .event.past .dateBox {
  background: #edf0f2;
}
#studio main section.events .calendar .event .dateBox {
  background: #fae9e9;
  padding: 0 12px;
  width: 76px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#studio main section.events .calendar .event .dateBox span {
  display: block;
  line-height: 1;
}
#studio main section.events .calendar .event .dateBox span.cta {
  margin-left: 4px;
}
#studio main section.events .calendar .event .general-content {
  flex: 1;
  display: flex;
  align-items: center;
}
#studio main section.events .calendar .event .general-content .content {
  flex: 1;
  padding: 0 36px;
}
#studio main section.events .calendar .event .general-content .content span {
  display: block;
  margin-bottom: 0;
}
#studio main section.events .calendar .event .general-content .content span.h3 {
  text-transform: none;
}
#studio main section.events .calendar .event .general-content .icon {
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#studio main section.events .calendar .event .general-content .icon svg {
  width: 36px;
}
#studio main section.events .calendar .event .general-content .icon span.body {
  margin: 3px 0 0;
  font-weight: bold;
  text-transform: lowercase;
}
#studio main section.events .newsletter {
  margin-top: 60px;
}
#studio main section.events .newsletter .input-box {
  width: 50%;
  max-width: 400px;
  margin-right: 30px;
}
#studio main section.team .definitions {
  height: 0;
}
#studio main section.team h2 {
  margin-bottom: 90px;
}
#studio main section.team .team-members {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#studio main section.team .team-members .member {
  position: relative;
  width: 30%;
  width: calc(33.33333333333333% - 48px);
  max-width: 256px;
  text-align: center;
  margin-bottom: 60px;
}
#studio main section.team .team-members .member:hover .socialBox,
#studio main section.team .team-members .member:hover svg .overlay {
  opacity: 1;
}
#studio main section.team .team-members .member .socialBox {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
#studio main section.team .team-members .member svg {
  display: block;
}
#studio main section.team .team-members .member svg .overlay {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
#studio main section.team .team-members .member svg text {
  font-family: "gellix", arial, sans-serif;
}
#studio main section.team .team-members .member h3 {
  margin: 18px 0 2px;
}
#studio main section.network {
  position: relative;
}
#studio main section.network .lottie {
  width: 15vw;
  top: -7.5vw;
  left: 12vw;
}
#studio main section.network .head {
  margin-bottom: 72px;
}
#studio main section.network .head h2 {
  margin-bottom: 24px;
}
#studio main section.network .partners {
  align-items: flex-start;
}
#studio main section.network .partners ul {
  margin: 24px 0 36px;
  padding: 0;
  list-style: none;
}
#vision main strong {
  background: #dff1f1;
}
#vision main em {
  background: #e7ebf4;
}
#vision main .heroShapes {
  height: 83.33333333333333vw;
  min-height: 100vh;
}
#vision main #lottie-vision-hexagon {
  right: 15vw;
  width: 25vw;
  top: 90vh;
}
#vision main section.hero {
  text-align: center;
}
#vision main section.hero .content {
  margin-top: 60px;
}
#vision main section.hero .content .ctaBox {
  margin-top: 72px;
}
#vision main section.why {
  padding-bottom: 0;
}
#vision main section.why .grid.noShape {
  align-items: flex-start;
}
#vision main section.why .title {
  margin-bottom: 90px;
}
#vision main section.why .why-block {
  margin-bottom: 180px;
}
#vision main section.why .why-block h3 {
  width: 80%;
  margin-bottom: 24px;
}
#vision main section.why .why-block p {
  width: 90%;
  margin-bottom: 12px;
}
#vision main section.why .why-block p.body a.simple {
  transform: translateY(9px);
}
#vision main section.why .why-block svg {
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  transform: translateX(10%) scale(1.5);
}
#vision main section.why .why-block svg.left {
  transform: translateX(-20%) scale(1.5);
}
#vision main .footerShape {
  display: block;
  position: relative;
  z-index: -1;
}
#work main .hero {
  padding-top: 144px;
  padding-bottom: 144px;
}
#work main .hero .h1,
#work main .hero .h3 {
  text-transform: none;
  text-align: center;
}
#work main .hero .h3 {
  margin: 24px 0 90px;
}
#work main .hero .main-picture {
  display: block;
  width: 100%;
  aspect-ratio: 744/465;
}
#work main section.content h3 {
  margin-bottom: 24px;
}
#work main section.content .stats-box {
  margin-top: 48px;
}
#work main section.content .stats-box .stats-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
#work main section.content .stats-box .stats-wrapper .data {
  width: 25%;
  margin-bottom: 18px;
}
#work main section.content .stats-box .stats-wrapper .data:nth-of-type(3n + 2) {
  margin-right: 12.5%;
  margin-left: 12.5%;
}
#work main section.content ul.expertises {
  margin: 36px 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
#work main section.content ul.expertises li {
  width: 50%;
  max-width: 360px;
}
#work main section.content ul.expertises li a.expertise-link {
  margin: 0 !important;
  display: inline-block;
  position: relative;
  overflow: clip;
}
#work main section.content ul.expertises li a.expertise-link::after,
#work main section.content ul.expertises li a.expertise-link::before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 1px;
  background: #1d1d1d;
  will-change: transform;
}
#work main section.content ul.expertises li a.expertise-link::before {
  opacity: 0.3;
  transition: transform 0.3s 0.1s ease-out;
}
#work main section.content ul.expertises li a.expertise-link::after {
  height: 2px;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}
#work main section.content ul.expertises li a.expertise-link:hover::before,
#work main section.content ul.expertises li a.expertise-link.current::before {
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}
#work main section.content ul.expertises li a.expertise-link:hover::after,
#work main section.content ul.expertises li a.expertise-link.current::after {
  transform: translateX(0%);
  transition: transform 0.3s 0.1s ease-out;
}
#work main section.detailed-content {
  margin-bottom: 144px;
}
#work main section.detailed-content div:first-child {
  margin-top: 0;
}
#work main section.detailed-content .display-picture {
  width: 100%;
}
#work main section.next-project {
  padding: 90px 0 180px;
}
#work main section.next-project h2.h1 {
  margin-bottom: 90px;
}
#work main section.next-project .workContainer .preview {
  width: 100%;
}
#work main section.next-project .workContainer .cta {
  color: #6e6f70;
}
#work main section.next-project .workContainer .h2.vignette {
  margin: 18px 0;
}
#work main section.next-project .workContainer .h3.vignette {
  margin-bottom: 0;
  color: #dff1f1;
}
@media screen and (min-width: 1600px) {
  #design main .hero {
    padding-bottom: 120px;
  }
  #design main .works .workContainer {
    margin-bottom: 180px;
  }
}
@media screen and (min-width: 1800px) {
  #design main .hero {
    padding-bottom: 180px;
  }
  #design main .works .workContainer {
    margin-bottom: 270px;
  }
}
@media screen and (min-width: 1600px) {
  #home main section.activity .infoOverlay .info.consultancy-shape svg {
    top: -50%;
    left: -55%;
    width: 205%;
    height: 205%;
  }
  #home main section.activity .infoOverlay .info.lab-shape svg {
    top: -55%;
    left: -50%;
    height: 195%;
    width: 195%;
  }
  #home main section.vision_with_echos .echos-box .echos-container {
    justify-content: space-between;
  }
  #home main section.vision_with_echos .echos-box .echos-container .echo {
    height: 90px;
  }
}
@media screen and (min-width: 1800px) {
  #home main section.activity .infoOverlay .info.consultancy-shape svg {
    top: -55%;
    left: -70%;
    width: 220%;
    height: 220%;
  }
  #home main section.activity .infoOverlay .info.lab-shape svg {
    top: -12.5vw;
    left: -8vw;
    height: 35vw;
    width: 35vw;
  }
}
@media screen and (min-width: 1600px) {
  #studio main section.hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1600px) {
  #vision main section.hero .grid {
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (max-width: 700px) {
  #article main .hero {
    padding-top: 120px;
    padding-bottom: 60px;
    text-align: left;
  }
  #article main .hero .h1 {
    margin: 18px 0;
  }
  #article main .hero .body.mention {
    margin-bottom: 30px;
  }
  #article main .hero .hero-image {
    margin-bottom: 36px;
  }
  #article main .detailed-content {
    padding-top: 60px;
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 1240px) {
  #contact main .blockContainer .block.step.newsletterSuccess .workContainer {
    flex-direction: column;
  }
  #contact main .blockContainer .block.step.newsletterSuccess .workContainer .previewBox,
  #contact main .blockContainer .block.step.newsletterSuccess .workContainer .contentBox {
    width: 100%;
    margin-right: 0;
  }
  #contact main .blockContainer .block.step.newsletterSuccess .workContainer .previewBox {
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 900px) {
  #contact main .blockContainer .block.step .newsletterBlock {
    flex-direction: column;
    align-items: flex-start;
  }
  #contact main .blockContainer .block.step .newsletterBlock .input-box {
    margin-right: 0;
    width: 100%;
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 700px) {
  #contact main {
    height: auto;
  }
  #contact main .grid {
    height: auto;
  }
  #contact main .grid .blockContainer {
    height: 100vh;
  }
  #contact main .grid .blockContainer .block.step h2 {
    margin-bottom: 12px;
  }
  #contact main .grid .blockContainer .block.step p {
    margin-bottom: 24px;
  }
  #contact main .grid .sidePanel {
    height: 80vh;
  }
  #contact main .grid.background {
    margin: 0;
    height: 80vh;
  }
  #contact main .grid.background::after {
    content: ' ';
    display: block;
    background: url("/assets/images/icons/arrow-scroll.svg") no-repeat center;
    background-size: contain;
    height: 48px;
    width: 48px;
    position: absolute;
    bottom: 30px;
    right: 12px;
    z-index: 100;
    filter: invert();
  }
  #contact main .grid.background .sideBackground {
    width: 100vw;
    transform: none;
  }
}
@media screen and (max-width: 500px) {
  #contact main .grid .blockContainer .block.step h2 {
    margin-bottom: 6px;
  }
  #contact main .grid .blockContainer .block.step p {
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 900px) {
  #design main .filtersList a.tag.lab {
    margin: 0 6px;
  }
  #design main .filtersList a.tag.lab::before {
    content: none;
  }
  #design main .works {
    padding-bottom: 90px;
  }
  #design main .works .workContainer {
    margin-bottom: 60px;
  }
  #design main .works .workContainer.lab .previewBox,
  #design main .works .workContainer.lab .contentBox {
    width: 100%;
  }
  #design main .works .workContainer.lab .contentBox {
    margin-top: 0;
  }
  #design main .works .workContainer .previewBox {
    order: 0;
  }
  #design main .works .workContainer .contentBox {
    margin-top: 18px;
  }
  #design main .works .workContainer .contentBox .h2 {
    margin: 9px 0;
  }
}
@media screen and (max-width: 700px) {
  #design main #lottie-design-hexagon {
    width: 70vw;
    left: -20vw;
  }
  #design main #lottie-design-pentagon {
    width: 30vw;
    top: 30vh;
  }
  #design main #lottie-design-bubbledDiamond {
    width: 35vw;
  }
  #design main .hero .catch {
    text-align: left;
  }
  #design main .filtersList {
    justify-content: flex-start;
    margin: 30px 12px 0;
  }
  #design main .works {
    padding-bottom: 48px;
  }
  #design main .works .workContainer.lab {
    background: #1d1d1d;
    margin-right: 0;
    margin-left: 0;
  }
  #design main .works .workContainer.lab .previewBox {
    padding: 0;
  }
  #design main .works .workContainer.lab .contentBox .content {
    padding: 24px 12px 30px;
  }
}
@media screen and (max-width: 900px) {
  .words-list li {
    width: 50%;
  }
}
@media screen and (max-width: 700px) {
  .words-list {
    margin-top: 36px;
  }
  .words-list li {
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  #home main section.activity {
    padding-top: 90px;
  }
  #home main section.activity.last {
    margin-bottom: 0;
  }
  #home main section.activity .image-box .display {
    width: 100%;
    transform: none;
  }
  #home main section.activity .infoOverlay {
    margin-top: 30px;
    position: static;
  }
  #home main section.activity .infoOverlay .info svg {
    display: none;
  }
  #home main section.trust {
    padding-bottom: 120px;
  }
  #home main section.trust .listing ul {
    margin-top: 18px;
  }
  #home main section.trust .listing div:last-of-type {
    margin-top: 30px;
  }
  #home main section.vision_with_echos .h2 {
    margin-bottom: 18px;
  }
  #home main section.vision_with_echos .echos-box .echos-container {
    padding-top: 48px;
  }
  #home main section.vision_with_echos .echos-box .echos-container .echo {
    height: 56px;
  }
}
@media screen and (max-width: 700px) {
  #home main section.hero .arrow-scroll {
    bottom: 60px;
  }
  #home main section.introduction {
    text-align: center;
  }
  #home main section.introduction .lottie {
    width: 45vw;
    right: 10vw;
    top: -20vw;
  }
  #home main section.key-datas {
    padding-bottom: 0;
  }
  #home main section.key-datas .shapes {
    top: 10vh;
    height: 200vw;
  }
  #home main section.key-datas .lottie {
    top: 80%;
    right: 15vw;
    width: 35vw;
  }
  #home main section.key-datas .grid {
    flex-direction: column;
  }
  #home main section.key-datas .grid .data {
    margin-bottom: 48px;
  }
  #home main section.activity .image-box {
    margin: 0;
  }
  #home main section.activity .image-box .display {
    padding: 0;
  }
  #home main section.trust {
    padding-bottom: 90px;
  }
  #home main section.trust .lottie {
    width: 65vw;
    top: 20%;
    bottom: unset;
  }
  #home main section.vision_with_echos .echos-box .echos-container {
    padding-bottom: 24px;
    justify-content: flex-start;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  #home main section.vision_with_echos .echos-box .echos-container .echo {
    height: 48px;
    margin-right: 32px;
  }
  #home main section.vision_with_echos .echos-box::after {
    content: '';
    display: block;
    background: url("/assets/images/icons/arrow-scroll.svg") no-repeat center;
    filter: invert();
    transform: rotate(-90deg);
    background-size: contain;
    width: 60px;
    height: 60px;
  }
}
@media screen and (max-width: 900px) {
  #studio main section.team .team-members .member {
    width: 45%;
    width: calc(50% - 24px);
  }
  #studio main section.events .newsletterBlock {
    flex-direction: column;
    align-items: flex-start;
  }
  #studio main section.events .newsletterBlock .input-box {
    margin-right: 0;
    width: 100%;
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 700px) {
  #studio main #lottie-studio-curved-diamond {
    width: 25vw;
    left: 15vw;
  }
  #studio main #lottie-studio-m {
    width: 62vw;
    right: -12vw;
    top: 15vh;
  }
  #studio main .heroShapes {
    top: 20vh;
    left: -20%;
  }
  #studio main section.hero h1 {
    margin-bottom: 36px;
  }
  #studio main section.hero h1,
  #studio main section.hero .body {
    text-align: left;
  }
  #studio main section.journey {
    margin: 180px 0 90px;
    height: auto;
    transform: none !important;
  }
  #studio main section.journey .background {
    height: 100%;
  }
  #studio main section.journey .journey-content {
    flex-direction: column;
    align-items: flex-start;
    width: 100vw;
    height: auto;
    padding: 90px 0;
    box-sizing: border-box;
    transform: none !important;
  }
  #studio main section.journey .journey-content .block {
    width: auto;
    margin: 0 0 36px;
    padding: 0 18px;
  }
  #studio main section.journey .journey-content .displays-mobile-container {
    width: 100%;
    height: 45vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-x: scroll;
  }
  #studio main section.journey .journey-content .displays {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0 18px;
  }
  #studio main section.journey .journey-content .displays .display {
    height: 30vh;
  }
  #studio main section.journey .journey-content .displays .display:first-of-type {
    margin-left: 0;
  }
  #studio main section.events .event-column h3 {
    margin: 24px 0 18px;
  }
  #studio main section.events .event-column p {
    margin-bottom: 48px;
  }
  #studio main section.events .calendar {
    margin-top: 24px;
  }
  #studio main section.events .calendar .event .dateBox {
    padding: 0 6px;
    width: 60px;
  }
  #studio main section.events .calendar .event .general-content {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 12px;
  }
  #studio main section.events .calendar .event .general-content .content {
    padding: 0;
  }
  #studio main section.events .calendar .event .general-content .icon {
    flex-direction: row;
  }
  #studio main section.events .calendar .event .general-content .icon svg {
    width: 20px;
    margin: 3px 6px 0 0;
  }
  #studio main section.team {
    padding-top: 120px;
  }
  #studio main section.team .team-members {
    flex-direction: column;
    align-items: center;
  }
  #studio main section.team .team-members .member {
    width: 100%;
  }
  #studio main section.team .contact-block {
    display: flex;
    justify-content: center;
  }
  #studio main section.network {
    padding-bottom: 90px;
  }
  #studio main section.network .lottie {
    width: 48vw;
    left: -6vw;
    top: -24vw;
  }
}
@media (pointer: coarse) {
  #studio main section.team .team-members .member {
    display: flex;
    flex-direction: column;
  }
  #studio main section.team .team-members .member .body {
    margin-bottom: 3px;
  }
  #studio main section.team .team-members .member .socialBox {
    order: 3;
    position: static;
    opacity: 1;
    aspect-ratio: unset;
  }
  #studio main section.team .team-members .member .socialBox a.simple {
    color: #1d1d1d;
  }
  #studio main section.team .team-members .member .socialBox a.simple::before,
  #studio main section.team .team-members .member .socialBox a.simple::after {
    background: #1d1d1d;
  }
}
@media screen and (max-width: 700px) {
  #vision main #lottie-vision-hexagon {
    top: 70vh;
    right: 10vw;
    width: 50vw;
  }
  #vision main .heroShapes {
    left: -15vw;
    width: auto;
  }
  #vision main section.hero {
    text-align: left;
  }
  #vision main section.hero .catch {
    text-align: left;
  }
  #vision main section.hero .content {
    margin-top: 30px;
  }
  #vision main section.hero .content .ctaBox {
    margin-top: 36px;
  }
  #vision main section.why .title {
    margin-bottom: 36px;
  }
  #vision main section.why .why-block {
    margin-bottom: 36px;
  }
  #vision main section.why .why-block svg,
  #vision main section.why .why-block svg.left {
    transform: translateY(-10%) scale(1.25);
  }
  #vision main section.why .why-block.picture {
    order: 2;
  }
  #vision main .footerShape {
    width: 100vw;
    height: 20vh;
  }
}
@media screen and (max-width: 500px) {
  #vision main .heroShapes {
    top: -10vh;
    left: -45vw;
    min-height: 150vh;
  }
}
@media screen and (max-width: 900px) {
  #work main section.content .stats-box .stats-wrapper {
    justify-content: space-between;
  }
  #work main section.content .stats-box .stats-wrapper .data {
    width: 40%;
    margin-bottom: 24px;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  #work main section.detailed-content {
    margin-bottom: 90px;
  }
  #work main section.detailed-content .photo-slice,
  #work main section.detailed-content .quote-slice {
    margin-top: 90px;
    margin-bottom: 90px;
  }
  #work main section.next-project {
    padding: 72px 0 144px;
  }
  #work main section.next-project h2.h1 {
    margin-bottom: 48px;
  }
  #work main section.next-project .workContainer .contentBox {
    margin-top: 18px;
  }
  #work main section.next-project .workContainer .contentBox .h2.vignette {
    margin: 9px 0;
  }
}
@media screen and (max-width: 700px) {
  #work main .hero {
    padding-bottom: 60px;
  }
  #work main .hero .h1,
  #work main .hero .h3 {
    text-align: left;
  }
  #work main .hero .h3 {
    margin: 12px 0 24px;
  }
  #work main section.content {
    padding-top: 60px;
  }
  #work main section.content .stats-box {
    margin-top: 24px;
  }
  #work main section.content .stats-box .stats-wrapper .data {
    width: 100%;
  }
  #work main section.content .stats-box .stats-wrapper .data .number {
    display: inline-block;
  }
  #work main section.content ul.expertises {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  #work main section.content ul.expertises li {
    width: 100%;
  }
  #work main section.detailed-content {
    padding-top: 72px;
    margin-bottom: 60px;
  }
  #work main section.detailed-content .photo-slice,
  #work main section.detailed-content .quote-slice {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  #work main section.next-project {
    padding: 60px 0 144px;
  }
  #work main section.next-project h2.h1 {
    margin-bottom: 30px;
  }
}
@media (pointer: coarse) {
  #home main section.vision_with_echos .echos-box,
  section.our-thinking-block .articles-box {
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  #home main section.vision_with_echos .echos-box::-webkit-scrollbar,
  section.our-thinking-block .articles-box::-webkit-scrollbar {
    display: none;
    height: 0;
    width: 0;
  }
}

/*# sourceMappingURL=app-ltt6kf40ny6hpue556b.css.map */
